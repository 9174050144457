@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900;6..12,1000&display=swap');

*{
  margin: 0;
  padding: 0;
}

body{
  max-width: 1480px;
  margin: 0 auto;
  font-family: 'Nunito Sans', sans-serif;
  overflow-x: hidden !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.down-arrow::after{
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

input[type=number]::-webkit-inner-spin-button{
  display: none;
}

table, thead, tbody, tr, th, td{
  border: 2px solid black
}

table{
  margin: 20px 0;
}

th {
  color: #000000;
}

td, th{
  padding: 5px;
}

.flex-summernote{
  display: flex;
  gap: 5px;
  margin: 10px 0;
}

.dropdown-selector::-webkit-scrollbar{
  width: 5px;
}

.dropdown-selector::-webkit-scrollbar-thumb{
  background-color: #a9a9a9;
}