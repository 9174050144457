.about-bg {
  background: linear-gradient(90deg, transparent 20%, #2575fc 70%),
    url("../images/contact-us-banner.png");
  background-position-x: left;
  background-position-y: top;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
}

.abt-bnr-bg {
  background: linear-gradient(90deg, #eeeeee 60%, transparent 70%),
    url("../images/about-images/about-banner.png");
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  min-height: 350px;
}

.pricing-bg {
  background-image: url("../images/page-title-bg2.jpg");
  background-position-x: center;
  background-position-y: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.about-bg-container {
  position: relative;
  z-index: 1;
  background-color: #00000091;
  background-position-x: center;
  background-position-y: -100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 100px;
  padding-bottom: 100px;
}

.about-our-section {
  background: linear-gradient(180deg, transparent 30%, #cbefff 50%);
}

.about-our-vision {
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  position: relative;
  z-index: -1;
  width: 100%;
  height: 300px;
}

.partner-img {
  position: relative;
  padding: 20px;
  background-color: #f4f7fc;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
}

.partner-img-1 {
  width: auto;
  transition: transform 500ms ease;
}

.partner-img-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 200%);
  transition: transform 500ms ease;
}

.partner-img:hover > .partner-img-1 {
  transform: translateY(-200%);
  transition: transform 500ms ease;
}

.partner-img:hover > .partner-img-2 {
  position: absolute;
  transform: translate(-50%, -50%);
  transition: transform 500ms ease;
}

.career-bg {
  background: linear-gradient(
      90deg,
      #2575fc 20%,
      #4f75b69c 50%,
      transparent 60%
    ),
    url("../images/career-images/career-banner.png");
  background-position-x: right;
  background-position-y: top;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
}

.media-award-bg {
  background: linear-gradient(
      90deg,
      #2575fc 10%,
      #4f75b69c 50%,
      transparent 60%
    ),
    url("../images/media-awards/awards-banner.jpeg");
  background-position-x: right;
  background-position-y: top, 45%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
}

.abt-sub-bg{
  background: url("../images/about-images/abt-sub-bg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

.abt-team-bg {
  background: url("../images/about-images/abt-group-img.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
}

.abt-team-bg-1 {
  background: url("../images/about-images/group-img-effect.png"),
    linear-gradient(0deg, #2575fccc 30%, #00000091 50%, transparent 70%);
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
}

.safexpian-work-bg {
  background: url("../images/career-images/safexpians-at-work-bg.png");
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
}

@media (min-width: 1024px) {
  .about-bg-container {
    padding-top: 200px;
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .about-our-section {
    position: relative;
    z-index: -1;
    background: linear-gradient(180deg, transparent 10%, #cbefff 30%);
  }

  .career-bg {
    background: url("../images/career-images/career-banner.png"), #2575fc;
    background-position-x: center;
    background-position-y: 0%;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 450px;
    height: auto;
  }

  .media-award-bg {
    background: url("../images/media-awards/awards-banner.jpg"), #2575fc;
    background-position-x: center;
    background-position-y: -50px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 300px;
  }

  .abt-bnr-bg {
    background: url("../images/about-images/about-banner.png"), #eeeeee;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
