.pay-aggregator-bg, .pay-easier-bg, .pay-card-bg, .sheets-bg, .integration-bg{
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.pay-aggregator-bg {
  background: url("../images/Products-images/payment-platform-banner.png");
  width: 100%;
  height: 500px;
}

.pay-easier-bg {
  background: url("../images/payout-images/pay-easier-bg.png");
}

.pay-card-bg {
  background: url("../images/payout-images/bulk-bg.png");
}

.sheets-bg {
  background: url("../images/payout-images/sheets-img-bg.png");
  width: 100%;
  height: 390px;
}

.payout-bg{
  background: linear-gradient(90deg, #FFFFFF 50%, #20313B00 60%), url("../images/payout-images/payout-banner.png");
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
  width: 100%;
  height: 450px;
}

.integration-bg {
  background: url("../images/payout-images/integration.png");
  width: 100%;
  height: 450px;
}

.integration_1-bg {
  background: url("../images/payout-images/integration1.png");
  background-size: cover;
  background-position: 40%;
  background-repeat: no-repeat;
  width: 100%;
  height: 550px;
}

.pa_integration {
  background: url("../images/Products-images/pa_integration-bg.png");
  background-size: cover;
  background-position: 40%;
  background-repeat: no-repeat;
  width: 100%;
  height: 450px;
}

.onox-bg{
  background: linear-gradient(90deg, #E6F7FF 50%, transparent 60%), url("../images/Products-images/onox-banner-bg.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
}

.whitelabel-bg {
  background: linear-gradient(90deg, transparent 20%, white 40%),
    url("../images/Products-images/whitelabel_banner.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  height: 400px;
  width: 100%;
}

.wl-bg {
  background: url("../images/Products-images/whitlabel-Section.png");
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  width: 100%;
}

.wl-solution-bg {
  background: url("../images/Products-images/whitelabel-support-image.png"),
  #eff8ff;
  background-size: contain;
  background-position: right 105px;
  background-repeat: no-repeat;
}

.onoxBg {
  background: url("../images/Products-images/onox_partner.png");
  background-size: contain;
  background-position: 0 100px;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
}

.one-app-bg {
  background: url("../images/Products-images/one_app-bg.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.pa_gb_bg {
  background: url("../images/Products-images/pa_grow-business-bg.png"), #f6f9fc;
  background-size: 50% 60%;
  background-position: right bottom;
  background-repeat: no-repeat;
}

.ecommerce-bg{
  background: linear-gradient(90deg, transparent 30%, #F3F9FE 40%),
    url("../images/Industries-images/ecommerce.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  width: 100%;
}

.education-bg{
  background: linear-gradient(90deg, transparent 30%, #F3F9FE 40%),
    url("../images/Industries-images/education.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  width: 100%;
}

.society-bg{
  background: linear-gradient(90deg, transparent 40%, #F3F9FE 50%),
    url("../images/Industries-images/society.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  width: 100%;
}

.retail-bg{
  background: linear-gradient(90deg, transparent 30%, #EFF7FF 45%),
    url("../images/Industries-images/retail.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  width: 100%;
}

.prt-pgr-footer-bg{
  background: url('../images//partner-images/join-partner-program-footer.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.step-square{
  position: relative;
  color: #FFFFFF;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.step-square::before{
  content: '';
  position: absolute;
  background-color: #007FFF;
  z-index: -1;
  width: 50px;
  height: 50px;
  border-radius: 5px;
  transform: rotateZ(45deg);
}

.dashed_path{
  position: relative;
}

.dashed_path::before, .dashed_path::after{
  content: "";
  position: absolute;
  z-index: -2;
  border-radius: 50%;
  width: 750%;
  height: 100px;
}

.dashed_path::before{
  bottom: -45%;
  right: 30%;
  border-bottom: 2px dashed #49E5B7;
}

.dashed_path::after{
  top: -45%;
  left: 30%;
  border-top: 2px dashed #49E5B7;
}

.offer-li::before {
  content: "\25CF";
  color: #32325D;
  font-weight: bold;
  display: inline-block;
  margin-left: 0;
  position: absolute;
  font-family: "FontAwesome";
  top: 0px;
  left: 0px;
}

.apix-bg {
  background: linear-gradient(90deg, #F3F7F7 50%, transparent 55%), url('../images/Products-images/apix-banner.png');
  padding: 50px 0;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
}

.begin-api-journey{
  background: url('../images/Products-images/begin-api-now.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 300px;
}

.industries-bg{
  background: url('../images/Products-images/whitlabel-Section.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; 
}

@media (max-width: 640px) {
  .pay-aggregator-bg {
    height: auto;
  }
  .integration-bg {
    height: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .sheets-bg {
    height: auto;
  }
  .onoxBg {
    height: 300px;
  }
  .pa_gb_bg {
    background: #f6f9fc;
  }
  .whitelabel-bg {
    height: auto;
    background-size: cover;
    background: #eff8ff;
  }
  .blue-bg{
    min-height: 250px;
    height: auto;
    max-height: 450px;
  }

  .ecommerce-bg, .education-bg, .society-bg, .retail-bg, .onox-bg{
    background-size: contain !important;
    background-position: top !important;
    background-repeat: no-repeat !important;
  }

  .ecommerce-bg{
    background: linear-gradient(360deg, #F3F9FE 55%, transparent 55%), url("../images/Industries-images/ecommerce.png");  
    height: 550px;
  }

  .education-bg{
    background: linear-gradient(360deg, #F3F9FE 55%, transparent 55%), url("../images/Industries-images/education.png");
    height: 550px;
  }

  .society-bg{
    background: linear-gradient(360deg, #F3F9FE 55%, transparent 55%), url("../images/Industries-images/society.png");
    height: 500px;
  }

  .retail-bg{
    background: linear-gradient(360deg, #00B5EA 55%, transparent 55%), url("../images/Industries-images/retail.png");
    height: 550px;
  }

  .onox-bg{
    background: url("../images/Products-images/onox-banner-bg.png"), #0C99D5;
    height: 500px;
  }

  .apix-bg {
    background: linear-gradient(180deg, #F3F7F7 55%, transparent 60%), url('../images/Products-images/apix-banner.png');
    height: 600px;
    background-size: contain !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
  }

  .whitelabel-bg {
    background: linear-gradient(360deg, transparent 40%, white 50%),
      url("../images/Products-images/whitelabel_banner.png");
      height: 500px;
      background-size: contain;
      background-position: bottom;
      background-repeat: no-repeat;
  }

  .payout-bg{
    background: linear-gradient(360deg, transparent 50%, white 50%),
      url("../images/payout-images/payout-banner.png");
      background-size: contain;
      background-position: bottom;
      background-repeat: no-repeat;
      height: 480px;
  }
  
  .begin-api-journey{
    background-size: cover;
  }

  .prt-pgr-footer-bg{
    background-size: cover;
  }
}

@media only screen and (max-width: 1024px) {
  .wl-solution-bg{
    background: #eff8ff;
  }
  .dashed_path::before, .dashed_path::after{
    display: none;
  }
}
