@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

.home-bnr-bg {
  background: url("../images/Safex\ Banners\ 4.png"), #c6f0f5;
  background-size: contain;
  background-position: 0 100%;
  background-repeat: no-repeat;
  vertical-align: middle;
  overflow: hidden;
}

.product-bg {
  background: url("../images/image\ 3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.achivment-bg {
  background: url("../images/achivment-bg.png");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 20px;
}

.also-feature-bg {
  background: radial-gradient(
    38.82% 50% at 50% 50%,
    rgba(17, 155, 215, 1) 0%,
    rgba(40, 60, 145, 1) 100%
  );
  background-color: #283c91;
  padding: 40px 0 20px;
}

.testimonial-bg {
  background: url("../images/testimonial.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0 0;
}

.choose-safex-bg {
  background: url("../images/choose-safexpay.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0;
}

.footer-li > li::before {
  content: "\25B8";
  color: #2faa4a;
  font-weight: bold;
  display: inline-block;
  margin-left: 0;
  position: absolute;
  font-family: "FontAwesome";
  left: 0px;
}

.footer-li-1 > li::before {
  content: "\25B8";
  color: #f09300;
  font-weight: bold;
  display: inline-block;
  margin-left: 0;
  position: absolute;
  font-family: "FontAwesome";
  left: 0px;
}

.steps::after,
.payout-steps::before,
.wl-steps::before {
  content: "";
  position: absolute;
  right: 50%;
  width: 100%;
  height: 130px;
  border-right: 2px dashed #2575fc;
}

.steps::after {
  z-index: -1;
  top: 67px;
}

.payout-steps::before,
.wl-steps::before {
  z-index: -2;
  bottom: 67px;
}

.video-effect::before {
  content: "";
  background: url("../images/Industries-images/video-before-effect.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  left: -45px;
  bottom: -55px;
  z-index: -10;
  width: 200px;
  height: 200px;
}

.image-effect::before {
  content: "";
  background: url("../images/Industries-images/img-effect.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  left: -25%;
  top: 15px;
  z-index: -10;
  width: 300px;
  height: 200px;
}

.terms-bg {
  background: url("../images/termsbg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 300px;
}

.marquee-effect {
  animation: home-marquee 35s linear 0s infinite reverse;
}

@keyframes home-marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2600px);
  }
}

.ripple-effect {
  color: #57647c;
}
.ripple-effect::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 100%;
  top: 0;
  left: 0;
  animation: rippleEffect 3s infinite;
  animation-delay: 0.3s;
}

.Img {
  font-style: italic;
  background-size: cover;
  background-repeat: no-repeat;
  /* max-width: 100%; */
  /* height: auto; */
  vertical-align: middle;
}

.Img-1 {
  font-style: italic;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100%;
  height: 200px;
  vertical-align: middle;
}

.ripple-effect:hover {
  color: #283c91;
  background: #283c91;
  border-color: #283c91;
}

.ripple-effect:hover > img {
  filter: brightness(100);
}

@keyframes rippleEffect {
  70% {
    box-shadow: 0 0 0 15px currentColor;
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 0 currentColor;
    opacity: 0;
  }
}

.dynamic-text {
  animation: dynamicTxt 4s infinite 1s;
  animation-delay: 1s;
}

@keyframes dynamicTxt {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-51px);
  }
  100% {
    transform: translateY(-102px);
  }
}

@media (200px <= width <= 400px) {
  .steps::after {
    height: 150px;
  }
  .payout-steps::before {
    height: 100px;
  }
}

@media (max-width: 1024px) {
  .wl-steps::before {
    height: 0;
  }
}

@media (min-width: 767px) {
  .home-bnr-bg {
    display: flex;
  }
}

@media (min-width: 1024px) {
  /* .home-about-section {
    display: flex;
  } */
  .home-bnr-bg {
    background-position: 0 150%;
  }
  .service-card {
    padding: 50px 0 10px;
    border-radius: 10px;
    position: relative;
  }
  .testimonial-nth-child:nth-child(2) {
    transform: translateY(80px);
  }

  .testimonial-nth-child:nth-child(3) {
    transform: translateY(-30px);
  }

  .footer-label::after {
    content: "";
    border-bottom: 1px solid #2faa4a;
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 35px;
  }
  .footer-label-1::after {
    content: "";
    border-bottom: 1px solid #f09300;
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 35px;
  }
  .aasaan_underline::after {
    left: -8px !important;
  }
  @keyframes home-marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-3460px);
    }
  }
  @keyframes dynamicTxt {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-96px);
    }
    100% {
      transform: translateY(-192px);
    }
  }
}

.aasaan_underline {
  position: relative;
}

.aasaan_underline::after {
  content: "";
  background-image: url("../images/aasaan-underline.png");
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  bottom: -10px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.aasaan-bg {
  background: url("../images//Products-images/aasaan-bg.png");
}

.green-spot-bg {
  background: radial-gradient(#184f48 0, transparent 63%);
  background-size: 100% 120%;
  background-position: center;
  background-repeat: no-repeat;
}

.b-border {
  position: relative;
}

.b-border::after {
  content: "";
  border-bottom: 4px solid #2575fc;
  width: 80px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.upper-quote {
  position: relative;
}

.upper-quote::before {
  content: "";
  background: url("../images/testimonial-images/double-quote.png");
  background-repeat: no-repeat;
  background-size: contain;
  top: -30px;
  left: -70px;
  z-index: 50;
  position: absolute;
  width: 70px;
  height: 70px;
}

.triangle-down {
  position: relative;
}
.triangle-down::after {
  content: "";
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 5px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid currentColor;
}

.radio-check {
  background: #e0e0e0;
  appearance: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 100%;
}
.radio-check:checked {
  background: url("../social-icons/radio-button.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.radio-check-one {
  background: #32325d;
  appearance: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 100%;
}
.radio-check-one:checked {
  background: url("../social-icons/tick.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.partner-banner {
  background: linear-gradient(90deg, #2575fc 20%, transparent 65%),
    url("../images/partner-images/partner-banner.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  width: 100%;
}

.partner-with-us-bg {
  background: url("../images/partner-images/partner-with-us-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.blog-share{
  position: relative;
  font-size: 20px;
  font-weight: 700;
  color: #32325d;
}

.blog-share::after{
  content: "";
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  width: 200px;
  border-bottom: 2px solid #32325d;
}

@media (max-width: 767px) {
  .video-effect::before {
    height: 100px;
    width: 100px;
    left: -8%;
    bottom: -15%;
  }
  .partner-banner {
    background: url("../images/partner-images/partner-banner.png"), #2575fc;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    height: 450px;
  }
}

.loader {
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.page-loader{
  width: 50px;
  height: 50px;
  border: 3px solid #32325d;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.d-flex > P >span, .d-flex> ul >li{
  display: flex;
  align-items: start;
  gap: 5px;
}

.skeleton--img{
  width: 100%;
  height: 185px;
  background-color: #949494;
  border-radius: 6px;
  overflow: hidden;
}

.skeleton--title, .skeleton--description, .skeleton--date{
  width: 100%;
  height: 20px;
  background-color: #949494;
  border-radius: 6px;
  overflow: hidden;
}

.skeleton--description{
  height: 80px;
}

.skeleton--img >span, .skeleton--title >span, .skeleton--description >span, .skeleton--date >span{
  background: linear-gradient(90deg, transparent 40%, #c7c7c7 50%, transparent 60%);
  width: 100%;
  height: 100%;
  display: block;
  animation: skeleton 3s linear infinite;
}

@keyframes skeleton {
  from{
    transform: translateX(-100%);
  }
  to{
    transform: translateX(100%);
  }
}

